import {
    HeaderWrapper, LayoutSettingIcon,
} from "../../../styles/layouts/main-styles";
import { DText, Flex} from "../../../styles/components/common/common-styles";
import {useRef} from "react";
import Text from "../../../core/utils/Text";


const MainHeader = ({onLayoutSetting}) => {

    const lsRef = useRef()

    return(
        <HeaderWrapper>
            <Flex fw fh justify='space-between'>
                <Flex>
                    {/*<img*/}
                    {/*    src={require('../../../assets/images/logo.png')}*/}
                    {/*    alt=' '*/}
                    {/*    width="52px"*/}
                    {/*/>*/}
                    <DText style={{ margin: '0 4px', fontWeight: 600 }} cFontSize={'1.5rem'} color={'#3B6978'}>
                        <Text tid={'rynet'} />
                    </DText>
                </Flex>

                <Flex>
                    <Flex ref={lsRef} style={{ margin: '0 8px' }}>
                        <LayoutSettingIcon size={20} onClick={() => onLayoutSetting(lsRef)} />
                    </Flex>
                </Flex>
            </Flex>
        </HeaderWrapper>
    )
}

export default MainHeader;

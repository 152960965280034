import {useMutation, useQuery} from "react-query";
import {postApi} from "../../fetch-api/post";
import {useMainContext} from "../../../contexts/main";
import {useNavigate} from "react-router-dom";
import {deleteApi} from "../../fetch-api/delete";
import {useQueryContext} from "../../../contexts/query";
import {SETTING_KEYS} from "../setting";
import {normalFetch} from "../../fetch-api/get";
import {useAclContext} from "../../../contexts/acl";
import {routes} from "../../../constants/routes";


const useProfileQuery = (onSuccess) => {

    const navigate = useNavigate()
    const { profile: { setToken } } = useMainContext()

    return useQuery(
        'profile', () => normalFetch('/auth/profile'),
        {
            enabled: false,
            select: res => res?.data?.data,
            retry: 0,
            onSuccess: (res) => {
                onSuccess && onSuccess(res)
            },
            onError: () => {
                setToken('')
                navigate(routes.home)
            }
        }
    )
}

const useSigninMutation = (onSuccess) => {
    const { setToast } = useQueryContext()

    try {
        const mutation = useMutation(
            'signin', (data) => postApi(data, '/auth/login'),
            {
                onSuccess
            }
        )
        return mutation
    }catch (err) {
        setToast({ isError: true, show: true, message: 'username-password-error' })
    }

}

const useVerifyOtp = () => {

    const { profile: {setToken} } = useMainContext()
    const navigate = useNavigate()
    const { reset } = useAclContext()

    return useMutation(
        'verify-otp', (data) => postApi(data, '/auth/verifyOtp'),
        {
            onSuccess: (res) => {
                const token = res?.data?.data?.token
                if (token) {
                    setToken(token)
                    navigate('/profile')
                    reset()
                }
            }
        }
    )
}

const useLogout = () => {

    const {profile: {setToken}} = useMainContext()
    const { reset } = useAclContext()

    return useMutation(
        'logout', () => deleteApi('/auth/logout'),
        {
            onSuccess: () => {
                setToken('')
                reset()
            }
        }
    )
}

const useRegisterUser = (onClose) => {
    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        'register-user', (data) => postApi(data, '/auth/register'),
        {
            onSuccess: () => {
                setToast({
                    message: 'op-success', show: true
                })
                queryClient.invalidateQueries(SETTING_KEYS.GET_USERS)
                onClose && onClose()
            }
        }
    )
}

const useUpdateUser = (onClose = () => {}) => {
    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        'update-user', (data) => postApi(data, `/auth/update/${data._id}`),
        {
            onSuccess: () => {
                setToast({
                    message: 'op-success', show: true
                })
                queryClient.invalidateQueries(SETTING_KEYS.GET_USERS)
                onClose()
            }
        }
    )
}

const useLotp = () => {

    const { profile: {setLOTP} } = useMainContext()
    const navigate = useNavigate()

    return useMutation(
        'lotp', (data) => postApi(data, '/auth/lotp'),
        {
            onSuccess: (res) => {
                setLOTP(res?.data?.data?.token)
                navigate(routes.landing)
            }
        }
    )
}

const useLotpRegisterUser = () => {

    const setToast = useQueryContext()

    return useMutation(
        'lotp-register-user', (data) => postApi(data, '/auth/register/user'), {
            onSuccess: () => {
                setToast({
                    message: 'op-success', show: true
                })
            }
        }
    )
}

export {
    useSigninMutation,
    useLogout,
    useRegisterUser,
    useProfileQuery,
    useUpdateUser,
    useVerifyOtp,
    useLotp,
    useLotpRegisterUser
}


const themePallets = {
	dark: [
		{ name: 'classic', mainBg: '#1e2026', primaryBg: '#2a2d35' },
		{ name: 'dark-sky', mainBg: '#2f3e46', primaryBg: '#354f52' },
		{ name: 'violet-owl', mainBg: '#22223b', primaryBg: '#4a4e69' },
		{ name: 'darker', mainBg: '#0b090a', primaryBg: '#161a1d' },
		{ name: 'smooth', mainBg: '#37323e', primaryBg: '#6d6a75' },
	],
	light: [
		{ name: 'classic', mainBg: '#E5E9F0', primaryBg: '#FAFBFC' },
		{ name: 'dark-sky', mainBg: '#C6DCE4', primaryBg: '#DAEAF1' },
		{ name: 'violet-owl', mainBg: '#D2DAFF', primaryBg: '#EEF1FF' },
		{ name: 'darker', mainBg: '#ffffff', primaryBg: '#EBF0F6' },
		{ name: 'smooth', mainBg: '#DFF4F3', primaryBg: '#F2FCFC' },
	]
}

const getMainTheme = (theme = 'dark', lang = 'fa', pallet) => {

	const chosenPallet = themePallets[theme].find(item => item.name === pallet) || {}

	const MainTheme = {
		dark: {
			secondaryBg: "#1F2228",
			color: '#c3c5b7',
			primary: '#d8dce1',
			hover: '#26282b',
			active: '#3B6978',
			mainOrange: '#ffc800',
			mainRed: '#e9106c',
			mainGreen: '#1ce087',
			clientMain: '#ffc800',
			clientPrimary: '#dcae09',
			inActive: '#72747830',
			search: '#161a1e',
			input: '#1A1C21',
			textArea: '#44464c',
			dropdown: '#36383C',
			skeletonBg: "#1e2026",
			skeleton: "#2a2d35",
			table: '#2A2C31',
			shadow: 'black',
			shimmer: "rgba(255,255,255, 0.05)",
			telegram: '#15202b',
			settingBg: '#fafafa',

			mlBg: 'white',
			mpBg: '#fafafa',
			mlc: '#0b090a',
			mpc: '#161a1d',
			mlb: '#0186d0'
		},
		light: {
			mainBg: '#E5E9F0',
			primaryBg: '#FAFBFC',
			color: '#191c20',
			primary: '#161a1e',
			hover: '#E5E9F090',
			active: '#3B6978',
			mainOrange: '#dcae09',
			mainRed: '#e9106c',
			mainGreen: '#15A865',
			clientMain: '#ffc800',
			clientPrimary: '#DFB620',
			inActive: '#72747830',
			search: '#8B8E92',
			input: '#F7F9FE',
			textArea: '#44464c',
			dropdown: '#CDCDCE',
			skeletonBg: "#f2f2f2",
			skeleton: "#ddd",
			table: 'white',
			shadow: '#ffffff40',
			shimmer: "rgba(255,255,255, 0.2)",
			telegram: 'white',
			settingBg: 'rgb(43, 49, 57)',

			mlBg: 'white',
			mpBg: '#fafafa',
			mlc: '#0b090a',
			mpc: '#161a1d',
			mlb: '#0186d0'
		},
	}

	let chosenTheme = { ...MainTheme[theme] }
	chosenTheme = {...chosenTheme, ...chosenPallet}
	chosenTheme.english = lang === 'en'
	return chosenTheme
}

export const availablePreferences = (theme) => {
	const prefs = []
	themePallets[theme]?.forEach(item => {
		prefs.push({
			name: item.name,
			main: item.mainBg,
			primary: item.primaryBg
		})
	})
	return prefs
}


const fadeVariants = {
	out: {
		opacity: 0, transition: { duration: 0.3 }
	},
	in: {
		opacity: 1, transition: { duration: 0.3 }
	}
}


const pageVariants = (lang) => ({
	out: {
		x: lang === 'en' ? '-100vw' : '100vw', transition: { duration: 0.5 }
	},
	in: {
		x: 0, transition: { duration: 0.5 }
	}
})

const getRandomColor = (idx) => {
	const randomColor = [
		'#818F9F', '#9F819E', '#9F9181', '#819F82'
	]
	return randomColor[idx % 4]
}

export {
	getMainTheme,
	fadeVariants,
	pageVariants,
	getRandomColor
}

import { useGetModerator } from "../../../../core/services/react-query/c2c";
import { MAWrapper, ModeratorInput, ModeratorWrapper, PostTab } from "../../../../styles/components/c2c";
import { CFlex, DText, Flex, LineBreak } from "../../../../styles/components/common/common-styles";
import { ClipLoader, ScaleLoader } from "react-spinners";
import ModeratorActions from "./ModeratorActions";
import { Fragment, useCallback, useEffect, useState } from "react";
import { deepCopy, formatDate } from "../../../../core/utils/common";
import { useProfileQuery } from "../../../../core/services/react-query/auth";
import { useMainContext } from "../../../../core/contexts/main";
import TelegramPost from "./TelegramPost";
import Text from "../../../../core/utils/Text";
import { IoMdRefresh } from "react-icons/io";
import { getMainTheme } from "../../../../core/utils/theme";


const C2cModerator = () => {

    const { main: { lang } } = useMainContext()
    const { data: profile, refetch: getProfile } = useProfileQuery()
    const { data, refetch: getModerator, isLoading: loadingModerator } = useGetModerator(profile?._id)

    useEffect(() => {
        const viewPort = document.getElementById('viewport')
        viewPort.setAttribute('content', 'width=device-width, initial-scale=1')

        return () => {
            viewPort.setAttribute('content', 'width=1250, initial-scale=1')
        }
    }, [])

    useEffect(() => {
        if (profile) getModerator()
        else getProfile()
    }, [profile])

    const [c2cs, setC2cs] = useState([])
    const [messages, setMessages] = useState([])
    const [tabs, setTabs] = useState([])

    useEffect(() => {
        if (data) {
            const _messages = []
            const _tabs = []
            for (let i = 0; i < data.length; i++) {
                const curr = data[i]
                const inner = []
                const innerTabs = []
                for (let j = 0; j < curr.posts.length; j++) {
                    inner.push(curr.posts[j].message)
                    innerTabs.push({
                        active: 0,
                        items: makeTabs(curr.posts[j])
                    })
                }
                _messages.push(inner)
                _tabs.push(innerTabs)
            }
            setMessages(_messages)
            setTabs(_tabs)
            setC2cs(data)
        }
    }, [data])

    const onEditMessage = (e, c2cIndex, postIndex) => {
        const _messages = deepCopy(messages)
        const { active, items } = tabs[c2cIndex][postIndex]
        const activeTab = items[active]

        _messages[c2cIndex][postIndex][activeTab] = e?.target?.value
        setMessages(_messages)
    }

    const calcHeight = (value) => {
        let numberOfLineBreaks = (value.match(/\n/g) || []).length;
        return 20 + numberOfLineBreaks * 20 + 12 + 2;
    }

    const handleRef = useCallback(node => {
        if (node) {
            console.log(node.scrollHeight)
            node.style.height = node.scrollHeight + "px"
        }
    }, [data])

    const makeTabs = (post) => {
        let temp = []
        if (!!post?.message?.telegram) temp.push('telegram')
        if (!!post?.message?.eitaa) temp.push('eitaa')
        return temp
    }

    const onChangeTab = (c2cIndex, postIndex, tabIndex) => {
        const newTabs = deepCopy(tabs)
        newTabs[c2cIndex][postIndex].active = tabIndex
        setTabs(newTabs)
    }

    const [defaultLoading, setDefaultLoading] = useState(false)
    const onRefreshModerator = () => {
        if (defaultLoading || loadingModerator) return
        getModerator()
        setDefaultLoading(true)
        setTimeout(() => {
            setDefaultLoading(false)
        }, 3000)
    }

    let mainIdx = 0

    console.log({ c2cs })

    return (
        <>
            <div className={'fixed top-[28px] left-[78px] z-[10000]'} onClick={onRefreshModerator}>
                {loadingModerator || defaultLoading ?
                    <ClipLoader size={20} color={getMainTheme().active} />
                    :
                    <IoMdRefresh size={22} color={getMainTheme().primary} />
                }

            </div>
            <Flex fw fh align={'start'}>
                <ModeratorWrapper>
                    <CFlex fw>
                        {loadingModerator ?
                            <ScaleLoader
                                color={'#3B6978'}
                                height={18}
                                width={2}
                            />
                            :
                            c2cs?.map((c2c, c2cIndex) => (
                                <Fragment key={c2c._id}>
                                    {c2c.posts.map((item, postIndex) => {

                                        const activeTab = tabs[c2cIndex][postIndex]
                                        const { active, items } = activeTab
                                        const activeTabName = items[active]
                                        const mgs = messages[c2cIndex][postIndex]
                                        const activeMg = mgs[activeTabName]

                                        if (item.status === 'sending') return <></>

                                        mainIdx += 1

                                        return (
                                            <Fragment key={item._id}>
                                                <Flex justify={`flex-${lang === 'en' ? 'end' : 'start'}`}>
                                                    <MAWrapper style={{ marginBottom: '6px', height: 'max-content', padding: '10px' }}>
                                                        <CFlex fw align={'flex-start'}>
                                                            <TelegramPost post={item} />

                                                            <Flex style={{ marginTop: '12px' }}>
                                                                {items?.map((t, idx) => (
                                                                    <PostTab key={`${idx}${Math.random()}`} active={active === idx} onClick={() => onChangeTab(c2cIndex, postIndex, idx)}>
                                                                        <Text tid={t} />
                                                                    </PostTab>
                                                                ))}
                                                            </Flex>

                                                            <LineBreak />
                                                            <ModeratorInput
                                                                ref={handleRef}
                                                                value={activeMg}
                                                                onChange={e => onEditMessage(e, c2cIndex, postIndex)}
                                                            />
                                                            <Flex fw justify={'flex-end'} margin={'8px 0 0 0'}>
                                                                <DText main cFontSize={'0.7rem'}>
                                                                    {formatDate(new Date(item.date * 1000), 'time', lang)}
                                                                </DText>
                                                            </Flex>
                                                        </CFlex>
                                                    </MAWrapper>
                                                </Flex>
                                                <ModeratorActions
                                                    c2c={c2c}
                                                    post={item}
                                                    message={mgs}
                                                    index={mainIdx}
                                                />
                                            </Fragment>
                                        )
                                    })}
                                </Fragment>
                            ))}
                    </CFlex>
                </ModeratorWrapper>
            </Flex>
        </>

    )
}



export default C2cModerator

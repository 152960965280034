import {Background, CFlex, DText} from "../../styles/components/common/common-styles";
import Text from "../../core/utils/Text";
import { ReactComponent as FingerPrint } from "../../assets/landing/finger-print.svg"
import RegisterSignin from "../auth/RegisterSignin";

const Home = () => {

    return (
        <CFlex fw height={'100vh'}>
            <Background bg={'mainBg'}>
                <FingerPrint width={200} />
                <div className={'flex flex-col justify-start items-start px-20 mt-8'}>
                    <div className={'w-[320px] flex flex-col gap-5'}>
                        <DText primary>
                            <Text tid={'raynet'} className={'font-semibold text-3xl tracking-wider'} />
                        </DText>
                        <RegisterSignin />
                    </div>
                </div>
            </Background>
        </CFlex>

    )
}

export default Home

import styled, {css} from "styled-components";
import {TiChevronRight} from "react-icons/ti";
import {Flex} from "../common/common-styles";


export const ModeratorWrapper = styled.div`
  height: calc(100vh - 80px);
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${props => props.theme.mainBg};
  border-radius: 4px;
  box-shadow: 0 0 4px rgb(0, 0, 0, 0.15);
  width: 480px;
  
  @media screen and (max-width: 768px) {
    position: fixed;
    left: 0;
    top: 80px;
    width: 100%;
  };
`


export const MAWrapper = styled.div`
  border-radius: 8px;
  background-color: ${props => props.theme.telegram};
  width: 316px;
  height: 48px;
  margin-top: -2px;
  margin-bottom: 12px;
  border: 1px solid ${props => props.theme.primary}40;
`


export const C2cIconWrapper = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: ${props => props.theme.inActive};
  cursor: pointer;
`

export const ModeratorBtn = styled.div`
  outline: 1px solid #4a9fbc;
  border-radius: 4px;
  height: 30px;
  padding: 0 8px;
  color: ${props => props.theme.primary};
  font-size: 0.8rem;
  background-color: ${props => props.theme.active};
  position: absolute;
  top: 10px;
  transition: all 0.3s;
  cursor: pointer;
  box-shadow: 0 0 4px rgb(0, 0, 0, 0.1);

  ${props => props.theme.english && css`
    right: 140px;
  `};

  ${props => !props.theme.english && css`
    left: 140px;
  `};

  &:hover {
    background-color: ${props => props.theme.active};
    color: whitesmoke;
  }
`


export const WizardLine = styled.div`
  width: ${props => props.width ? `${props.width}px` : '100px'};
  height: 1px;
  background-color: ${props => props.active ? props.theme.mainOrange : props.theme.primary};
  margin: 0 4px;
  transition: all 0.3s;
`


export const WizardStatus = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props => props.completed ? props.theme.mainGreen : props.theme.mainRed};
`

export const WizardOpenCloseIcon = styled(TiChevronRight)`
  color: ${props => props.active ? props.theme.mainOrange : props.theme.color};
  transform: rotate(90deg);
  margin-bottom: -6px;
  transition: all 0.3s;
  
  ${props => props.active && css`
    transform: rotate(270deg);
  `};
`

export const C2cNext = styled.div`
  background-color: ${props => props.theme.mainOrange};
  color: #161a1e;
  font-size: 0.8rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  width: 100px;
  margin-top: 16px;
  cursor: pointer;
`

export const ModificationGrid = styled.div`
  display: grid;
  width: 100%;
  height: calc(100% - 80px);
  grid-template-columns: repeat(12, 100/12%);
  grid-template-rows: 260px 250px 250px;
  grid-template-areas: 
    "a a a b b b c c c d d d"
    "e e e e f f f f g g g g"
    "h h h h h h i i i i i i"
  ;
`

export const ModificationBox = styled.div`
  outline: 1px solid ${props => props.theme.primary}15;
  grid-area: ${props => props.area};
`

export const ModificationHeader = styled(Flex)`
  justify-content: flex-start;
  padding: 8px;
  width: 90%;
  margin: 8px;
  box-shadow: 0 0 8px rgb(0, 0, 0, ${props => props.theme.shadow});
  border-radius: 4px;
  background-color: ${props => props.color}90;
`

export const ModificationItemBody = styled.div`
  padding: 8px 16px;
  opacity: ${props => props.active ? 1 : 0.5};
  position: relative;
  
  ${props => !props.active && css`
    &::after {
      content: ' ';
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
      left: 0;
      top: 0;
    }
  `};
`


export const ModeratorInput = styled.textarea`
  background-color: transparent;
  color: ${props => props.theme.color};
  border: none;
  outline: none;
  width: 300px;
  min-width: 280px;
  max-width: 300px;
`


export const TelegramMediaBody = styled.div`
  width: 100%;
  height: 240px;
  background-color: ${props => props.theme.mainBg};
  color: ${props => props.theme.color}80;
  position: relative;
`


export const FileWrapper = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 4px rgb(0, 0, 0, 0.05);
  width: 100%;
  height: 52px;
  background-color: ${props => props.theme.mainBg};
  padding: 0 20px;
`

export const FileIconWrapper = styled.div`
  border-radius: 50%;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${props => props.theme.active};
  color: white;
`

export const PostTab = styled.div`
  font-size: 0.7rem;
  color: ${props => props.active ? props.theme.mainOrange : props.theme.color};
  margin: 0 8px;
  position: relative;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${props => props.active && css`
    &::after {
      content: ' ';
      position: absolute;
      width: 32px;
      height: 1px;
      left: 0;
      bottom: -4px;
      background-color: ${props => props.theme.mainOrange};
    }
  `};
  
`

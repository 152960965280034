import {MainBody, MainContainer} from "../../../styles/layouts/main-styles";
import {useMainContext} from "../../../core/contexts/main";
import {getMainTheme} from "../../../core/utils/theme";
import MainHeader from "./MainHeader";
import {ThemeProvider} from "styled-components"
import {useRef, useState} from "react";
import {CFlex} from "../../../styles/components/common/common-styles";
import Toast from "../../common/utils/Toast";
import GlobalLoading from "../../common/loading/GlobalLoading";
import FilterContextProvider from "../../../core/contexts/filter";
import Restricted from "../acl/Restricted";
import useClickOutside from "../../../core/hooks/common/useClickOutside";
import {AnimatePresence} from "framer-motion";
import LayoutSetting from "./LayoutSetting";
import {routes} from "../../../core/constants/routes";



const MainLayout = ({children, loading}) => {

    const {main: {theme, lang, sidebar}, colorPref} = useMainContext()
    /**
     * header setting
     */
    const ref = useRef()
    const lsIconRef = useRef()
    const [ layoutSettingOpen, setLayoutSettingOpen ] = useState(false)
    const closeLayoutSetting = (e) => {
        if (!lsIconRef?.current?.contains(e.target)) {
            setLayoutSettingOpen(false)
        }
    }
    useClickOutside(ref, closeLayoutSetting)
    const openLayoutSetting = (ref) => {
        lsIconRef.current = ref.current
        setLayoutSettingOpen(state => !state)
    }


    const publicRoutes = [routes.home, routes.landing, routes.register]
    const PUBLIC = publicRoutes.includes(window.location.pathname)
    const SPECIAL = window.location.pathname === '/' || window.location.pathname === '/rayna'

    return(
        <FilterContextProvider>
            <ThemeProvider theme={getMainTheme(theme, lang, colorPref)}>
                <Restricted>
                    <MainBody sidebar={sidebar} hasHeader={!SPECIAL}>
                        {!SPECIAL &&
                            <MainHeader
                                onLayoutSetting={openLayoutSetting}
                            />
                        }
                        <AnimatePresence exitBeforeEnter>
                            {layoutSettingOpen &&
                                <LayoutSetting
                                    ref={ref}
                                />
                            }
                        </AnimatePresence>
                        {loading ?
                            <GlobalLoading />
                            :
                            <MainContainer sidebar={sidebar} login={PUBLIC}>
                                <CFlex
                                    justify='flex-start'
                                    align='flex-start'
                                    fw
                                >
                                    {children}
                                </CFlex>
                            </MainContainer>
                        }
                        <Toast />
                        {/*<Navigator />*/}
                        {/*<LimiterModal />*/}
                        {/*<NeedToBuyPackage />*/}
                    </MainBody>
                </Restricted>
            </ThemeProvider>
        </FilterContextProvider>
    )
}

export default MainLayout;

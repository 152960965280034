import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {useSetAxiosHeaders} from "../core/hooks/common/useSettings";
import {AnimatePresence} from "framer-motion";

/**
 * pages
 */
import RegisterSignin from "./auth/RegisterSignin";
import Sims from "./main/Sims";
import Groups from "./main/Groups";
import Channels from "./main/Channels";
import CreateSim from "../components/main/sim/CreateSim";
import SimDetails from "../components/main/sim/SimDetails";
import Dashboard from "./main/Dashboard";
import Categories from "./main/Categories";
import Operations from "./actions/Operations";
import CreateOperation from "../components/main/operation/CreateOperation";
import OperationDetails from "../components/main/operation/details/OperationDetails";
import MainLayout from "../components/layouts/main/MainLayout";
import ChatDetails from "../components/main/details/ChatDetails";
import Normalizers from "./actions/Normalizers";
import CreateNormalizer from "../components/main/normalizer/CreateNormalizer";
import NormalizerDetails from "../components/main/normalizer/details/NormalizerDetails";
import Posts from "./actions/Posts";
import CreatePost from "../components/main/post/CreatePost";
import PostDetails from "../components/main/post/details/PostDetails";
import Logs from "./setting/Logs";
import {SocketProvider} from "../core/contexts/socket";
import {SocketContentProvider} from "../core/contexts/socket-content";
import C2c from "./actions/C2c";
import C2cModerator from "../components/main/c2c/moderator/C2cModerator";
import CreateC2c from "../components/main/c2c/create/CreateC2c";
import C2cDetails from "../components/main/c2c/C2cDetails";
import Setting from "./setting/Setting";
import AclContextProvider from "../core/contexts/acl";
import Profile from "./auth/Profile";
import Sender from "./actions/Sender";
import C2cDashboard from "./main/C2cDashboard";
import SenderDetails from "../components/main/sender/SenderDetails";
import Manager from "./setting/Manager";
import CreateWorkspace from "../components/main/setting/workspaces/CreateWorkspace";
import CreatePackage from "../components/main/setting/workspaces/CreatePackage";
import Jobs from "./setting/Jobs";
import {routes} from "../core/constants/routes";
import Home from "./landing/Home";
import {useMainContext} from "../core/contexts/main";
import Landing from "./landing/Landing";
import Register from "./landing/Register";
import Messages from "./setting/Messages";
import {useEffect} from "react";


const Index = () => {

    const location = useLocation()
    useSetAxiosHeaders()
    const { profile: { token } } = useMainContext()
    const navigate = useNavigate()
    useEffect(() => {
        if (!!token) navigate(routes.moderator)
        else navigate(routes.home)
    }, [token])

    return (
        <AclContextProvider>
            <AnimatePresence exitBeforeEnter>
                <MainLayout>
                    <SocketProvider>
                        <SocketContentProvider>
                            <Routes location={location} key={location.pathname}>
                                {!!token ?
                                    <Route path={routes.moderator} element={<C2cModerator />} />
                                :
                                    <Route path={routes.home} element={<Home />} />
                                }
                            </Routes>
                        </SocketContentProvider>
                    </SocketProvider>
                </MainLayout>
            </AnimatePresence>
        </AclContextProvider>
    )
}

export default Index;

import styled from "styled-components";
import {Flex} from "../../../styles/components/common/common-styles";
import {FiAlertTriangle} from "react-icons/fi";


const Announcement = ({ children }) => {

    return (
        <Wrapper>
            <Body>
                <Flex justify={'flex-start'}>
                    <FiAlertTriangle color='#996A13' size={18} style={{ margin: '0 8px' }} />
                    {children}
                </Flex>
            </Body>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  position: fixed;
  top: 80px;
  min-width: 750px;
  left: calc(50% - 375px);
`

const Body = styled.div`
  padding: 4px 12px;
  border: 1px solid #FFB020;
  border-radius: 0 0 4px 4px;
  color: #996A13;
  background-color: #FFFAF2;
  font-size: 0.8rem;
`

export default Announcement
